import { GiKnifeFork } from "react-icons/gi";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { IoWifi } from "react-icons/io5";
import { GrUserManager } from "react-icons/gr";
import { LuChefHat } from "react-icons/lu";
import { FaSwimmingPool } from "react-icons/fa";
import { GiPowerGenerator } from "react-icons/gi";
import { FaPeopleRoof } from "react-icons/fa6";
import { PiBathtubBold } from "react-icons/pi";
import { PiElevatorFill } from "react-icons/pi";

export const API_URL = 'https://sohamvillas.com/web-backend';

export const VillaImages = {

    "HOME MOBILEBANNER": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a43b788a-0e75-4ce1-b201-cc5af6e39900/public" }, //mb1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f3c276c4-a2ed-4e91-2518-3b982c888c00/public" }, //mb2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8893d016-9866-4d09-55d1-645494197c00/public" }, //mb3.WEBP
        // { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6d509838-f1e4-41cc-20ef-e0aeb5237c00/public" }, //mb4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ad807c39-c4ac-4e7e-9b0f-a2e154258900/public" }, //mb5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e66d9f57-9218-45da-d093-8c10d6bf3b00/public" }, //3_1m.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/44e6734f-ee9f-48d1-a9a2-fa94e3665d00/public" }, //3_2m.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/69c444f1-6f8c-4934-47c1-abb4b4e8e500/public" }, //3_3m.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d5b00a80-1aca-4522-3b7a-bfffedb9a500/public" }, //3_4m.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/62fc6815-8e8a-4f74-4762-d756b2374100/public" }, //3_5m.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ddbe81d3-0020-425e-acee-eb6a70d24a00/public" }, //3_6m.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/886e303a-30f1-4629-64f5-2ec379bc6f00/public" }, //3_7m.WEBP
    ], 

    "HOME BANNER": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/08e9be17-f136-4238-1307-71970c216e00/public" }, //home_banner.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/12ee737f-4c0c-444f-cd0b-2ccdaec55c00/public" }, //b2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/cc8df595-a99b-4cca-1dcb-a6171b085200/public" }, //b5.WEBP
        // { src: "../assets/3_1.webp" },
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/77ac942c-d3a6-4058-4ff1-6d4e58cfe700/public" }, //3_2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/bb9ced96-5929-4238-7ec5-4b91c09f2700/public" }, //3_3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/175487e6-7fbc-44d6-440b-fd1eb2153d00/public" }, //3_4.WEBP
        // { src: "../assets/3_5.webp" },
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/16509db3-6492-4980-e1bb-e2f33cda5800/public" }, //3_6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/85f86496-442e-4931-96d4-cd7a9b8a2d00/public" }, //3_7.WEBP
    ],    

    "VILLA ARCADIA": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/35dbcc1d-9094-4d19-d210-53e192896200/public" }, //Arcadia1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0de4c8f2-0bcd-4c9a-d4ed-c200d2234c00/public" }, //Arcadia2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6ff01fd4-302c-467a-99e8-aedfcda63e00/public" }, //Arcadia3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fdf1d677-4f7c-4197-4d62-6e7770bc7100/public" }, //Arcadia4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d032dd1d-204e-482c-43b8-da8465758800/public" }, //Arcadia5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/878b6eb4-3cfc-4d46-9a29-409809d24e00/public" }, //Arcadia6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e93e41ab-6401-407b-2d3e-f5fee48d2c00/public" }, //Arcadia7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0ab3a38f-fe77-4304-3ed2-e6be8dc4ef00/public" }, //Arcadia8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2b1882dc-c01c-40e4-9d19-a19c6f2d1600/public" }, //Arcadia9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c394a9bd-131e-41c7-840d-f44f77130400/public" }, //Arcadia10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/32d95654-22e5-48d5-e56b-c1da69ea4800/public" }, //Arcadia11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/97058af6-5779-4dc8-9cdf-bdff66f35a00/public" }, //Arcadia12.WEBP
    ],
    "VILLA SERENITY": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8f2ee8b7-6293-44d7-0561-66937cdd9a00/public" }, //Serenity1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/371b1a2b-6144-4690-c79d-4480281b6d00/public" }, //Serenity2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c77acdd8-6efe-45cd-39de-80cc8ee28800/public" }, //Serenity3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/71ab84ce-d1a4-4f93-a2ef-f99d8d857e00/public" }, //Serenity4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b5b66508-189a-4415-d9be-a43c3eac2300/public" }, //Serenity5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/743f66ef-c796-49fe-1b06-0326b196cb00/public" }, //Serenity6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/dab244e9-46f0-48e5-d075-71cc69650100/public" }, //Serenity7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2411f8a2-a7be-49b1-da32-4650301d8a00/public" }, //Serenity8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ac07deb1-2764-4c6d-9751-7738bc053f00/public" }, //Serenity9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/471ed1d1-4b79-4af3-0a77-dca885152200/public" }, //Serenity10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7cb57072-73ac-4259-d56c-ddaa11ec0e00/public" }, //Serenity11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/74777baa-298e-4c9e-2bc2-e04ddf9eb400/public" }, //Serenity12.WEBP
    ],
    "HOUSE OF BASHŌ": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4edc78e2-6ec6-4bd2-5e8b-595541845900/public" }, //Basho1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/11a1ebc5-4569-4188-7396-ed251a38b400/public" }, //Basho2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6df3dc71-bccf-4cf0-69ec-c5a82dcd8600/public" }, //Basho3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/837751d3-feae-4521-3905-5f3190040c00/public" }, //Basho4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f2618fd0-2b4f-4731-012b-7e82d4131b00/public" }, //Basho5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/bb345579-f6bf-41a6-31a2-fca1c8c38600/public" }, //Basho6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a9d56371-5778-4efa-a0d0-5c66e964be00/public" }, //Basho7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6a132525-5297-46a3-4300-623023426400/public" }, //Basho8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/913f7a73-740a-45f4-356c-d2cc90698700/public" }, //Basho9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9cd34a81-7370-4fbf-4410-f256b5940800/public" }, //Basho10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/09c2f345-da72-45bf-62f3-d1f880951900/public" }, //Basho11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c29751b2-c5d6-44dd-5aa9-a9b363e67300/public" }, //Basho12.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1548b0ab-b11e-4af1-fbee-609fdc96f800/public" }, //Basho13.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a42a79db-015a-443a-ac10-61208d6c6a00/public" }, //Basho14.WEBP
    ],
    "VILLA CELEBRATION": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9276b15a-a56d-42ee-3cc1-d5c2f2abef00/public" }, //Celebration2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d5c6e27f-d341-4e94-44d4-eb769890a900/public" }, //Celebration3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ec0a660b-a00a-436a-21e7-4e1ec10cb100/public" }, //Celebration4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6f076d0a-5034-4ac4-5ad1-d64cb04a4d00/public" }, //Celebration5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/67cecc0b-61e0-4888-f988-11e9e6ccd600/public" }, //Celebration6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1ce5f454-e3e4-43a1-5d75-e5d4f6760900/public" }, //Celebration7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/53afc1b9-f5e7-4864-33e1-1b6c52976700/public" }, //Celebration9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f3932dd9-e77e-48df-e731-3bfa2a6ce400/public" }, //Celebration10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8e131569-130e-47a7-dbee-6acd87265700/public" }, //Celebration11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/31bb012e-8725-4afa-92e0-06ab53f66b00/public" }, //Celebration12.WEBP
    ],
    "VILLA LUXO": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ebed53d0-5111-4814-ce1d-21fda93a1c00/public" }, //Luxo2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/47ad53a6-6394-4241-8d3d-3de6a6edfa00/public" }, //Luxo3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9f8ac1d5-3af4-4b62-596a-eba3ef8d4d00/public" }, //Luxo4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/204d7f51-3a1b-4de1-aead-b805b2cf4300/public" }, //Luxo5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e45e808e-ee45-4368-22d2-f25009327b00/public" }, //Luxo6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1c78d02b-be72-41c8-534c-af5c81514800/public" }, //Luxo7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/880e0d37-4b1a-438a-d980-bee25c193400/public" }, //Luxo8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fccfd692-0589-460f-b035-ad4ad6aa5b00/public" }, //Luxo9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8a652b9b-f718-45a8-8a50-867cad853e00/public" }, //Luxo1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/62b5f9b3-3e99-4e74-9d1a-f5854039c000/public" }, //Luxo10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ffd1574c-444f-492b-534c-e7451a8c6300/public" }, //Luxo11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c777b7cf-ecce-433a-335a-ab41ed6d8e00/public" }, //Luxo12.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6c48faf9-efbc-4228-cded-ad6b18b25b00/public" }, //Luxo13.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2dcb354c-10f1-4c7e-f233-949286cebd00/public" }, //Luxo14.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/26510e74-4761-412e-1dba-f515f045cc00/public" }, //Luxo15.WEBP
    ],
    "VILLA CHAPORA": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8f7f5ead-f40f-4222-b0b1-7e3e845de500/public" }, //Chapora2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8ce4196f-ee1a-4559-f14a-080c1c29c800/public" }, //Chapora3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/10911003-63ce-4ebe-734a-d1ebefe02800/public" }, //Chapora4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2cfac50b-e2ea-4616-717b-afe019c0f200/public" }, //Chapora5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/330d1299-c306-491d-bf41-322bcb11cf00/public" }, //Chapora6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/99ee11f0-de84-4d5f-adb3-077a783ab700/public" }, //Chapora7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8947b884-7593-40ff-a688-78db97494c00/public" }, //Chapora1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e0c70f39-6356-4196-df88-9d12bf88d000/public" }, //Chapora8.WEBP
    ],
    "VILLA MANDOVI": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/87556dde-34f5-4fa1-7b19-827e9cbe2100/public" }, //Mandovi4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/07a834d1-1fb0-4964-b9ef-126b0ad68100/public" }, //Mandovi5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/193520e7-4b78-45dd-0421-35d2fde3a800/public" }, //Mandovi2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/94300955-068c-4d1d-c5c1-df98ac86aa00/public" }, //Mandovi3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0ee011ba-8cee-4fd8-a7df-06a4b4081100/public" }, //Mandovi6.WEBP
        // { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6d0e7efb-bdc5-4de2-8583-d06150ebd400/public" }, //Mandovi1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b1b02085-3cfb-474a-cdc2-1c47ddafcc00/public" }, //Mandovi7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e3f46a08-be76-4536-2aaf-fbac8e06cf00/public" }, //Mandovi8.WEBP
    ],
    "VILLA VERISSIMO": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3af2d4ed-b2de-419a-89ba-925db9c18600/public" }, //Versimo1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4a36a94c-9821-4096-aac1-ddaed7bd5e00/public" }, //Versimo2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fb815455-769a-43db-d2d2-fe3db84cf300/public" }, //Versimo3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8587c5e4-9d7c-44de-8fb8-e27ef72d3100/public" }, //Versimo4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b032ae3d-cba2-474e-9d41-30131d635c00/public" }, //Versimo5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b45d330a-6ffc-48aa-3b2f-661ff1805300/public" }, //Versimo6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/450003e6-e47a-472e-e10b-e7d72babf100/public" }, //Versimo7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0f0c3baf-0a30-4dad-f5ff-c76aae87d500/public" }, //Versimo8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c9332fb8-2a00-4b77-486a-e96ad83bcd00/public" }, //Versimo9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/be8b20c3-a0f0-4006-5ad7-d36f1924c100/public" }, //Versimo10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aaf2a23d-fef8-4467-0fbe-fa4ac9f6f900/public" }, //Versimo11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e3168fa8-724c-4c1f-9f65-30758ca90c00/public" }, //Versimo12.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a38c7781-8a12-42f1-49ba-8126a81fa700/public" }, //Versimo13.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d62bb8f4-4f81-4ae3-57aa-eaa27ea69800/public" }, //Versimo14.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c2e4dab0-644a-412e-1fc5-709d006ebf00/public" }, //Versimo15.WEBP
    ],
    "VILLA SAL": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5cd5d2f9-d7cf-4fe6-e033-ad94e7204000/public" }, //Sal1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ad165dfd-675f-42b4-54e1-d75a351d0200/public" }, //Sal2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aea51868-eb71-4ccd-0161-f12799bb0c00/public" }, //Sal3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/20a384e1-ff6d-4afb-f083-17b1ad366a00/public" }, //Sal4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f646f390-cd11-40d7-bf69-d08e379b5d00/public" }, //Sal5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/60ce98d8-b919-412b-f89c-946af9b62400/public" }, //Sal6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/91279375-87ea-45b0-82d4-bf031f996500/public" }, //Sal7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b078931e-f156-40b9-90ae-c68da182b100/public" }, //Sal8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2a84041f-2f57-4028-8c27-7360b6cbb200/public" }, //Sal9.WEBP
    ],
    "VILLA ZUARI": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7942f59f-1c68-4c19-d174-fa7744213700/public" }, //Zuari1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/61731d38-801a-4c77-fbf1-833b65057300/public" }, //Zuari2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/935020e5-c378-4bc5-3298-e55328e5d200/public" }, //Zuari3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f800ddd5-87c9-41fd-21e0-22914f6e9800/public" }, //Zuari4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8200f1a3-4c97-463e-dd48-40d9c3461900/public" }, //Zuari5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/23ea0d1f-9cb9-4732-69d8-434db0800f00/public" }, //Zuari6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ab1fee-23d0-4dce-d438-9070d2b11000/public" }, //Zuari7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d1d44c03-f8d9-4cde-c37b-3d5de35a0400/public" }, //Zuari8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7098b73e-e751-4519-e1bd-38cb4c90ff00/public" }, //Zuari9.WEBP
    ],
    "VILLA VERA G-7": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/63f19bdc-9f23-4e5d-4fed-1fca2114e000/public" }, //AQ1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/47bcd8d3-bc24-4f82-3d52-44a0665dc700/public" }, //AQ2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/461b11d1-dc12-4e44-d464-b89bd4e39d00/public" }, //AQ3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/931dd0c3-4bb3-463e-544f-a0edf3ce9f00/public" }, //AQ4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/62ef75a5-c25e-4416-9c27-9da17aa69200/public" }, //AQ5.WEBP    
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/862ae8c4-89a8-43d6-65de-eb4d60552900/public" }, //AQ6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/455a7e74-e9f6-4d9e-2071-e1f7ac94d100/public" }, //AQ7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c49e8bf3-1377-4a26-3068-91a1b176a000/public" }, //AQ8.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a1f620de-8027-4b7e-c7af-e204d57aa600/public" }, //AQ9.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e1b57e22-0d44-4d6d-60a3-1e5643c72300/public" }, //AQ10.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d2337f81-9ab4-487f-2524-d81d8c85ad00/public" }, //AQ11.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/da64b5c8-33d4-4253-5817-d87d69ddc400/public" }, //AQ12.WEBP
    ],
    "VISION GREENS G-1C": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/feb5f8e1-008e-42f1-e544-4191f9e8d200/public" }, //f1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a1af0971-7902-40b5-fd17-dbb2e7807b00/public" }, //f2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0dde9b92-2942-4374-ebea-ba3f29a02200/public" }, //f3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/dc09a9d0-1eb7-4f53-61b5-42429f1f0100/public" }, //f4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c8920319-6029-4602-c9b2-42022b905100/public" }, //f5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4f81759b-51b1-48ff-ffd9-f7cd6ae33100/public" }, //f6.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5078823f-bce4-4ae1-0d3c-c51b53765c00/public" }, //f7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6e29622e-6b83-4f2a-a3ec-02ef2c1c6200/public" }, //f8.WEBP
    ],
    "AQUA GRANDIOSA - 201": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14ed8c8a-5fc9-457a-a1cb-6947377a0500/public" }, //VV1.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3221cfd3-a4b1-4d65-d1b8-b501b03d3500/public" }, //VV2.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3f15d6fd-f53e-4295-7d14-5dec8a21ce00/public" }, //VV3.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/78f4914a-d10c-4538-bcc8-e2e9c52fe300/public" }, //VV4.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/785bd261-8291-4fee-af78-79f2dd3d1f00/public" }, //VV5.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/873e146c-10e5-49cf-ccfa-4548b8569400/public" }, //VV7.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/eae47f7f-2f7c-4116-fdc3-46db0f4b9b00/public" }, //VV8.WEBP
    ],
}

export const villaAmenities = {
    "VILLA ARCADIA": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <FaPeopleRoof />, text: 'Rooftop Lounge' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
        { icon: <PiElevatorFill />, text: 'Private Elevator' },

    ],
    "VILLA SERENITY": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <FaPeopleRoof />, text: 'Rooftop Lounge' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
        // { icon: <PiElevatorFill />, text: 'Private Elevator' },
    ],
    "HOUSE OF BASHŌ": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
    ],
    "VILLA CELEBRATION": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
        { icon: <FaPeopleRoof />, text: 'Rooftop Lounge' },
        { icon: <PiBathtubBold />, text: 'Jacuzzi' },
    ],
    "VILLA LUXO": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA CHAPORA": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA MANDOVI": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA VERISSIMO": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA SAL": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA ZUARI": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Smart Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Fully Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Private Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VILLA VERA G-7": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Basic Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Common Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "VISION GREENS G-1C": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Basic Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Common Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
    "AQUA GRANDIOSA - 201": [
        { icon: <GrUserManager />, text: 'Caretaker' },
        // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
        { icon: <MdOutlineScreenshotMonitor />, text: 'Television' },
        { icon: <IoWifi />, text: 'Wireless Internet' },
        { icon: <GiKnifeFork />, text: 'Basic Equipped Kitchen' },
        { icon: <FaSwimmingPool />, text: 'Common Pool' },
        { icon: <GiPowerGenerator />, text: '24x7 Power Backup' },
    ],
};

export const nearbyServices = {
    "VILLA ARCADIA": [
        { name: 'Baga Beach', rating: 5, link: 'https://maps.app.goo.gl/XNmUDhsghqqJMTh59' },
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA SERENITY": [
        { name: 'Baga Beach', rating: 5, link: 'https://maps.app.goo.gl/XNmUDhsghqqJMTh59' },
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "HOUSE OF BASHŌ": [
        { name: 'Club Cabana', rating: 5, link: 'https://maps.app.goo.gl/JJwXhwV6nCvhqW1d6' },
        { name: 'Hammerz Club', rating: 5, link: 'https://maps.app.goo.gl/YGmhCaQjkoxu9fPh9' },
        { name: 'Anjuna Beach', rating: 5, link: 'https://maps.app.goo.gl/AojvDzUenbG6oiUF9' }
    ],
    "VILLA CELEBRATION": [
        { name: 'Baga Beach', rating: 5, link: 'https://maps.app.goo.gl/XNmUDhsghqqJMTh59' },
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: ' Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA LUXO": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Anjuna Beach', rating: 5, link: 'https://maps.app.goo.gl/AojvDzUenbG6oiUF9' }
    ],
    "VILLA CHAPORA": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA MANDOVI": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA VERISSIMO": [
        { name: 'Soro Club', rating: 5 },
        { name: 'Ozran Beach', rating: 5 },
    ],
    "VILLA SAL": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA ZUARI": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VILLA VERA G-7": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "VISION GREENS G-1C": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],
    "AQUA GRANDIOSA - 201": [
        { name: 'Next to Radisson Resort Baga', rating: 5, link: 'https://maps.app.goo.gl/BuPETYmpMxzs4wZA9' },
        { name: 'Baba Au Rhum', rating: 5, link: 'https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA' },
        { name: 'Arpora Saturday Night Market', rating: 5, link: 'https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9' }
    ],

}

export const SafetyAndHygiene = {
    "VILLA ARCADIA": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Smoke Detectors" } //protection_icon.WEBP
    ],
    "VILLA SERENITY": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Smoke Detectors" } //protection_icon.WEBP
    ],
    "HOUSE OF BASHŌ": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA CELEBRATION": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Smoke Detectors" } //protection_icon.WEBP
    ],
    "VILLA LUXO": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA CHAPORA": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA MANDOVI": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA VERISSIMO": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA SAL": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA ZUARI": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
    ],
    "VILLA VERA G-7": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Smoke Detectors" } //protection_icon.WEBP
    ],
    "VISION GREENS G-1C": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Smoke Detectors" } //protection_icon.WEBP
    ],
    "AQUA GRANDIOSA - 201": [
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Daily Cleaning" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Fire Extinguishers" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Disinfections and Sterilizations" }, //protection_icon.WEBP
        { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public", text: "Smoke Detectors" } //protection_icon.WEBP
    ],

}