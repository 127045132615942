import React, { useState } from 'react';
import '../Home/Home.css';
import { HiOutlineArrowLongUp } from "react-icons/hi2";

const ShortFooter = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [isOpen, setIsOpen] = useState(false);

    const handleFloatingAction = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className='bg-[#FAFAFA] text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-7 md:leading-8 lg:leading-7 2xl:leading-10'>

                {/* Footer section starts from here */}
                <div className='bg-[#877D6F] text-white px-[5%] pt-[8%] lg:pt-[5%] pb-[3%]'>
                    <div className='flex flex-col lg:flex-row'>

                        <button className='absolute right-5 lg:right-[3%] 2xl:right-[5%]' onClick={scrollToTop} name='scrollToTop_btn'>
                            <HiOutlineArrowLongUp className='border rounded-full h-10 w-10 lg:h-12 lg:w-12 2xl:h-14 2xl:w-14 p-1 hover:bg-white hover:text-[#877D6F]' />
                        </button>

                        <div className='mx-[2%] lg:w-[30%] mb-[5%]'>
                            <img className='h-8 md:h-10 2xl:h-14' src="../assets/white_soham_logo.webp" alt="SohamVillas-Logo" /> {/* soham_logo_bg.webp */}
                            <p className='w-[80%] my-[6%] leading-5 2xl:leading-7'>
                                We make the world of luxury, meet the comfort of home, with our crafted collection of villas and
                                apartments.
                            </p>
                            <a href="https://www.instagram.com/sohamvillas" target="_blank" rel="noreferrer" className='heading_light my-[1%] hover:underline' style={{ letterSpacing: '1px' }}>INSTAGRAM</a><br />
                            <a href="https://www.facebook.com/sohamvillas" target="_blank" rel="noreferrer" className='heading_light my-[1%] hover:underline' style={{ letterSpacing: '1px' }}>FACEBOOK</a>
                        </div>

                        <div className='flex flex-col mx-[2%] lg:w-[25%] mb-[5%]'>
                            <p className='heading underline text-xl md:text-2xl 2xl:text-4xl mb-[5%] lg:mb-[10%]' style={{ letterSpacing: '4px' }}>&nbsp;&nbsp;QUICK LINKS&nbsp;&nbsp;</p>
                            <a href="/BookVilla" className='hover:underline'>VILLAS</a>
                            <a href="/Apartments" className='hover:underline'>APARTMENTS</a>
                            <a href="/Blogs" className='hover:underline'>BLOGS</a>
                            {/* <a href="/" className='hover:underline'>SOHAM BROCHURE</a> */}
                            <a href="/Experiences" className='hover:underline'>EXPERIENCES</a>
                        </div>

                        <div className='flex flex-col mx-[2%] lg:w-[47%] mb-[5%]'>
                            <p className='heading underline text-xl md:text-2xl 2xl:text-4xl mb-[5%] lg:mb-[6%]' style={{ letterSpacing: '4px' }}>&nbsp;&nbsp;CONTACT & RESERVATIONS&nbsp;&nbsp;</p>
                            <a href="/" className='mb-[5%] hover:underline'>HELLO@SOHAMVILLAS.COM</a>
                            <a href="/" className='hover:underline'>+91&nbsp;77670&nbsp;47535</a>
                            <a href="/" className='hover:underline'>+91&nbsp;88662&nbsp;62368</a>
                            <a href="/" className='hover:underline'>+91&nbsp;95105&nbsp;98151</a>
                        </div>
                    </div>
                    <hr />

                    <div className='flex flex-col lg:flex-row justify-between'>
                        <div className='flex gap-10'>
                        {['TERMS', 'REFUND POLICY', 'PRIVACY POLICY'].map((policy, idx) => (
                            <a key={idx} href={`/${policy.replace(' ', '')}`} className='heading_light hover:scale-105' style={{ letterSpacing: '1px' }}>{policy}</a>
                        ))}
                        </div>
                        <a href='https://inittechnology.co' className='heading_light text-white hover:underline mt-[5%] lg:mt-0' target='_blank' rel="noreferrer" style={{ letterSpacing: '1px', fontSize:'12px' }}>ALL RIGHTS RESERVED BY SOHAM VILLAS | COPYRIGHTS © INIT TECHNOLOGY 2024</a>
                    </div>
                </div>
                {/* Footer section ends here */}

                <div className={`fab ${isOpen ? 'open' : ''}`} onClick={handleFloatingAction}>
                    <span className={`fab ${isOpen ? 'rotate_floatingAction_btn' : 'floatingAction_btn'}`}>+</span>
                    <div className="fab-menu">

                        <a href="https://www.instagram.com/sohamvillas/" target="_blank" rel="noreferrer">
                            <img className='bg-white border-2 rounded-full h-[1.8em] w-[1.8em]' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5ca08b60-f697-47b7-27aa-88265db53400/public" alt="Instagram-Icon" /> {/* Instagram_icon_soham.webp */}
                        </a>

                        <a href="https://wa.me/+918866262368" target="_blank" rel="noreferrer">
                            <img className='bg-white border-2 rounded-full h-[1.8em] w-[1.8em]' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5584a9c6-9f95-463c-54eb-32fd89e84800/public" alt="Whatsapp-Icon" /> {/* whatsapp_icon_soham.webp */}
                        </a>

                        <a href="tel:+918866262368" target="_blank" rel="noreferrer">
                            <img className='bg-white border-2 rounded-full h-[1.8em] w-[1.8em]' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5c2c284d-7eb2-4bc9-77f9-01ee38cbf400/public" alt="Call-Icon" /> {/* call_icon_soham.webp */}
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ShortFooter;