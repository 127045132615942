import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';

const schema = {
  "@context": "http://schema.org",
  "@type": "LocalBusiness",
  "name": "Soham Villas",
  "description": "xperience luxurious living at Soham Villas in Goa, where modern design meets serene surroundings.",
  "image": "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e529387-135b-4057-f676-99cd978ad700/publicp",
  "telephone": "+917767047535",
  "email": "hello@sohamvillas.com",
  "url": "http://www.sohamvillas.com/",
  "aggregateRating": {
    "@type": "AggregateRating",
    "bestRating": "5"
  }
}



const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
    <App />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
