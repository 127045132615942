import React, { useState, useEffect, useCallback } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
// import { HiOutlineUser } from "react-icons/hi";
import { RiArrowDropDownLine } from "react-icons/ri";

const Header = () => {
    const navigate = useNavigate();
    const [header, setHeader] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleBookVilla = useCallback(() => {
        navigate('/BookVilla');
    }, [navigate]);

    // const handleSignIn = useCallback(() => {
    //     navigate('/SignIn');
    // }, [navigate]);

    const handleToggleChange = useCallback(() => {
        setIsChanged(prevState => !prevState);
        setMenuOpen(!menuOpen);
        setIsDropdownVisible(false);
    }, [menuOpen]);

    const changeHeaderBg = useCallback(() => {
        if (window.scrollY >= 200) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            changeHeaderBg();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [changeHeaderBg]);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleProperties = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[15px] 2xl:text-xl leading-7 md:leading-8 lg:leading-7 2xl:leading-10'>
            <div className={header ? 'header_active' : 'header'}>
                <div className='hidden lg:block'>
                    <button className='bookVilla_btn hidden lg:block rounded-lg mx-2 hover:text-[#877D6F]' onClick={handleBookVilla} name='reserveVilla_btn'>
                        RESERVE<span className='underscore'>_</span>YOUR<span className='underscore'>_</span>VILLA
                    </button>
                </div>
                <div>
                    <a href="/">
                        <img className='h-8 md:h-10 2xl:h-14' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e529387-135b-4057-f676-99cd978ad700/public" alt="SohamVillas-Logo" /> {/* soham_logo_new.webp */}
                    </a>
                </div>
                <div className='flex items-center justify-between ml-16'>
                    {/* <button className='mx-2 lg:mx-3' onClick={handleSignIn}>
                        <HiOutlineUser className='h-5 w-5 lg:h-8 lg:w-8 text-[#737373]' />
                    </button> */}
                    <div className='mx-2 lg:mx-3'>  
                    <div className='h-5 w-5 lg:h-8 lg:w-8 text-[#737373]' />  
                    </div>
                    <div className={isChanged ? "change" : ""} onClick={handleToggleChange}>
                        <div className='mx-2 lg:mx-3'>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                        </div>
                    </div>
                </div>
                <div className='menu_bg fixed h-screen w-screen' style={{ right: menuOpen ? 0 : '-100vw', top: 0, transition: 'right 0.5s ease-in-out' }}>
                    <div className='text-lg md:text-2xl flex flex-col text-right mt-20 mr-[7%] lg:mr-[4%]'>
                        <div className='flex justify-end'>
                            <button className='lg:hidden rounded-lg px-[2%] my-[3%] lg:my-[1%] font-medium hover:underline border border-black' onClick={handleBookVilla} name='reserveVilla_btn'>
                                RESERVE<span className='underscore'>_</span>YOUR<span className='underscore'>_</span>VILLA
                            </button>
                        </div>
                        <a href='/Home' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Home</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />

                        <button className='flex justify-end items-center my-[3%] lg:my-[1%] font-medium hover:underline group' onClick={handleProperties} name='properties_btn'>
                            Properties <RiArrowDropDownLine className={`${isDropdownVisible ? 'rotate-180' : ''} transition-transform`} />
                        </button>
                        {isDropdownVisible && (
                            <>
                                <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                                <Link to='/BookVilla' className='my-[3%] lg:my-[1%] text-[#877D6F] font-medium hover:underline'>Villa</Link>
                                <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                                <Link to='/Apartments' className='my-[3%] lg:my-[1%] text-[#877D6F] font-medium hover:underline'>Apartments</Link>
                            </>
                        )}
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/ConciergeServices' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Concierge Services</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/PropertyManagement' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Property Management</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/Experiences' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Experiences</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/OurStory' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Our Story</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/FAQs' className='my-[3%] lg:my-[1%] font-medium hover:underline'>FAQs</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/Blogs' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Blogs</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <a href='/ContactUs' className='my-[3%] lg:my-[1%] font-medium hover:underline'>Contact Us</a>
                        <hr className='flex ml-auto w-[60%] lg:w-[25%]' />
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;